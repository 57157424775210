import React from 'react'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Carousel from 'react-material-ui-carousel'
import Paper from '@material-ui/core/Paper'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    wrapper: {
        /*border: 'none'*/
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#FAFAFA',
        minHeight:'110vW',
        [theme.breakpoints.up('md')]: {
            minHeight: '50vH'
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: '65vH'
        }
    },
    carousel :{
       height:'100%',
       width:'100%'
        //minHeight:'90vH',
        /* [theme.breakpoints.down('md')]: {
            minHeight: '110vW'
          }, */
    },
    carouselInner:{
        padding: '0 1rem 0 1rem',
        [theme.breakpoints.down('sm')]:{
            padding:0
        },
        [theme.breakpoints.up('lg')]:{
            padding: '0 6rem 0 6rem',
        },

    },
    image: {
        width: '100%',
        height: '100%',
        

        /*Scale down will take the necessary specified space that is 100px x 100px without stretching the image*/
        objectFit: 'contain;',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            width: '100%;',
            
          },

    }


}))



export default function SlideShow({ images }) {
    const classes = useStyles()
    return (
        <Grid container justify='center' className={classes.root}>
            <Grid item xs={12} md={12} lg={12} className={classes.carouselInner}>
                <Carousel
                    className={classes.carousel}
                    indicatorContainerProps={{
                        style: {
                            position: 'absolute',
                            bottom:0
                            
                        }
                
                    }}
                    unmountOnExit
                >
                    {
                        images.map(image =>
                            <Paper elevation={0} key={image.id} className={classes.wrapper} square>
                                <Img
                                    fluid={image.localFile.childImageSharp.fluid}
                                    key={image.id}
                                    alt={image.title.replace(/-/g, ' ').substring(2)}
                                    className={classes.image}
                                />
                            </Paper>
                        )
                    }
                </Carousel>
            </Grid>
        </Grid>
    )
}
