
function toTitleCase([first, ...rest],locale=navigator.language){
    return [first.toLocaleUpperCase(locale),...rest].join('')
  }




function turkishToLower (text) {
	var string = text;
	var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
	string = string.replace(/(([İIŞĞÜÇÖ]))/g, function(letter){ return letters[letter]; })
	return string.toLowerCase();
}	

function turkishToUpper (text) {
    var string = text;
	var letters = { "i": "İ", "ı": "I", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç" };
	string = string.replace(/(([iışğüöç]))/g, function(letter){ return letters[letter]; })
	return string.toUpperCase();
}
export {toTitleCase, turkishToLower, turkishToUpper}