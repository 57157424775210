import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import { ListItemAvatar, Typography } from '@material-ui/core'
import { AiOutlineBarcode } from '@react-icons/all-files/ai/AiOutlineBarcode'
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=>({
    root: {
        display:'block',
        [theme.breakpoints.up('md')]:{
            display:'flex'
        }
    },
    avatar: {
        backgroundColor: 'black'
        //backgroundColor:"#09A9DC"
    }
}))

const Packages = (props) => {
const classes = useStyles()
    return (
        <List className={classes.root}>
            {props.list.map(item => {
                const weight = parseFloat(item.weightInGrams)
                const weightText = (weight >= 1000) ? `${weight / 1000} Kg` : `${weight} g`
                return (
                    <ListItem key={item.id} disableGutters>
                        <ListItemAvatar >
                            <Avatar className={classes.avatar}>
                                <AiOutlineBarcode/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={weightText}
                            secondary={<><Typography variant="subtitle2" component="span">{item.productCode}
                            </Typography><br/>
                                <Typography variant="subtitle2" component="span">
                                    {' EAN:'}{item.barcode}</Typography></>}
                        />
                        
                    </ListItem>



                )

            })}
        </List>
    )
}
export default Packages