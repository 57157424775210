import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { CgFacebook } from '@react-icons/all-files/cg/CgFacebook'
import {CgTwitter} from '@react-icons/all-files/cg/CgTwitter'
import {CgMail} from '@react-icons/all-files/cg/CgMail'
import {SiWhatsapp} from '@react-icons/all-files/si/SiWhatsapp'

const useStyles = makeStyles((theme)=>({
root:{
    marginTop: theme.spacing(2)
}
}))


export default function SocialShare({ customPath, customTitle, siteUrl }) {
    const shareUrl = `${siteUrl}${customPath}`
    const subject = customTitle
    const classes = useStyles()
    return (
        <Grid container justify='center' spacing={2} className={classes.root}>
            <Grid item>
                <EmailShareButton
                url={shareUrl}
                subject={subject}
                >
                    <CgMail size={32}  />
                </EmailShareButton>
            </Grid>
            <Grid item>
                <FacebookShareButton 
                url={shareUrl}
                quote={subject}
                >
                    <CgFacebook size={32}  />
                </FacebookShareButton>
            </Grid>
            
            
            <Grid item>
                <TwitterShareButton
                url={shareUrl}
                title={subject}
                >
                    <CgTwitter  size={32} />
                </TwitterShareButton>
            </Grid>
            <Grid item>
                <WhatsappShareButton
                    url={shareUrl}
                    title={subject}
                    separator=": "
                >
                    <SiWhatsapp size={28} 
                    
                    />
                </WhatsappShareButton>
            </Grid>




        </Grid>
    )
}
