import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import Packages from '../../components/packageOptions'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { translations } from '../../intl/translations'
import Slideshow from '../../components/Slideshow/slideshow'
import SocialShare from '../../components/socialShare'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { turkishToUpper } from '../../utilities/formatting'


const styles = theme => ({
  root: {
    width: '100%',
    flexDirection: "column",
    marginTop: theme.spacing(5)

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    flexDirection: "column",
    flexGrow: 1
  },
  slideShowContainer: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    }
  },
  textContainer: {
    display: 'none',
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  textContainerMobile: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  headingContainer: {
    marginTop: theme.spacing(2)
  },
  accordionHeader: {
    color: theme.palette.text.hint

  },
  gridWrapper: {
    height: '100%',
    marginTop: theme.spacing(10)
  },
  tableHead: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  mainSlideShow: {
    backgroundColor: 'yellow'
  },
  mainTextContainer: {
    backgroundColor: 'red'
  }

});
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);



const renderers = {
  table: ({ children }) => (
    <Table className="table-fit" size="small">{children}</Table>
  ),
  tableHead: ({ children }) => (
    <TableHead>{children}</TableHead>
  ),
  tableBody: ({ children }) => (
    <TableBody>{children}</TableBody>
  ),
  tableRow: ({ children }) => (
    <StyledTableRow>{children}</StyledTableRow>
  ),
  tableCell: ({ children }) => (
    <StyledTableCell>{children}</StyledTableCell>
  ),
  paragraph: ({ children }) => (
    <div style={{ marginBottom: '5px', fontSize: '1rem' }}>{children}</div>
  ),
  text: ({ children }) => (
    <>{children}</>
  ),
};



class ProductDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: 0 }
  }
  handleClick = (id) => {
    if (this.state.open === id) { this.setState({ open: 0 }) }
    else {
      this.setState({ open: id })
    }
  }

  render() {

    const { breadcrumb } = this.props.pageContext
    const locale = this.props.pageContext.nodeLocale
    const { classes, location } = this.props;
    const product = get(this.props.data, 'contentfulProduct')
    const siteMeta = get(this.props.data, 'site')




   

    let images = product.ambPhotos ? [...product.ambPhotos] : []

    if (product.packagePhoto) {
      images.push(product.packagePhoto)
    }
    if (product.kibblePhoto) {
      images.push(product.kibblePhoto)
    }
    if (product.productPhoto) {
      images.push(product.productPhoto)
    }

    return (
      <Layout nodeLocale={locale} location={location} crumbs={breadcrumb.crumbs} crumbLabel={product.name}>
        <SEO title={product.name} lang={locale} location={location} crumbs={breadcrumb.crumbs} crumbLabel={product.name} description={product.longDescription} />
        <Grid container justify="center">

          <Grid item xs={12} className={classes.textContainerMobile}>
            <Typography variant="overline" className="colorSecondary colorTextSecondary">{turkishToUpper(product.teaser)}</Typography>
            <Typography variant="h3" component="h1">{product.name}</Typography>
          </Grid>
          <Grid container direction="row" spacing={10} alignItems='stretch'>
            <Grid item xs={12} md={6} className={`${classes.slideShowContainer}`}>
              <Slideshow images={images} />
              <SocialShare customPath={this.props.pageContext.pagePath} customTitle={`${product.brand.brandName}-${product.name}`} siteUrl={siteMeta.siteMetadata.url} />
            </Grid>

            <Grid xs={12} md={6} item >
              <Grid container className={classes.slideShowContainer}>
                <Grid item xs={12} className={classes.textContainer}>
                  <Typography variant="overline" className="colorSecondary colorTextSecondary">{turkishToUpper(product.teaser)}</Typography>
                  <Typography variant="h3" component="h1">{product.name}</Typography>


                  <p>{product.longDescription?.longDescription}</p>
                </Grid>
                <Grid item xs={12} md={12} lg={6} className={classes.headingContainer}>
                  <Typography variant="h5">{translations[locale].packages}</Typography>
                  <Packages list={product.sku} />
                </Grid>
                <Grid item className={classes.root} xs={12} md={10}>
                  <Typography variant="h5">{translations[locale].prodDetails}</Typography>
                  {product.ingredients?.ingredients &&
                  <Accordion
                    className={classes.headingContainer}
                    expanded={this.state.open === 1}
                    onChange={() => this.handleClick(1)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="h5" className={classes.accordionHeader}>
                        {translations[locale].ingredients}
                      </Typography></AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <ReactMarkdown plugins={[gfm]} renderers={renderers} children={product.ingredients?.ingredients} />
                    </AccordionDetails>
                  </Accordion>}
                  {product.nutritionAdditives?.nutritionAdditives &&
                  <Accordion
                    expanded={this.state.open === 2}
                    onChange={() => this.handleClick(2)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant="h5" className={classes.accordionHeader}>{translations[locale].functionals}</Typography></AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <ReactMarkdown plugins={[gfm]} renderers={renderers} children={product.nutritionAdditives.nutritionAdditives} />
                    </AccordionDetails>
                  </Accordion>}
                  {product.analysis?.analysis &&
                  <Accordion
                    expanded={this.state.open === 3}
                    onChange={() => this.handleClick(3)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    ><Typography variant="h5" className={classes.accordionHeader}>{translations[locale].analysis}</Typography></AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <ReactMarkdown plugins={[gfm]} renderers={renderers} children={product.analysis?.analysis} />
                    </AccordionDetails>
                  </Accordion>}
                  {product.feedingRecommendation?.feedingRecommendation &&
                  <Accordion
                    expanded={this.state.open === 4}
                    onChange={() => this.handleClick(4)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    ><Typography variant="h5" className={classes.accordionHeader}>{translations[locale].recommendation}</Typography></AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <ReactMarkdown plugins={[gfm]} renderers={renderers} children={product.feedingRecommendation?.feedingRecommendation} />
                    </AccordionDetails>
                  </Accordion>}
                </Grid>
              </Grid>

            </Grid>



          </Grid>
        </Grid>








      </Layout >
    )
  }
}

ProductDetail.propTypes = {
  data: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ProductDetail)
export const query = graphql`
 query ProductDetailQuery($productId: String, $nodeLocale: String)
 {
    contentfulProduct(id: {eq: $productId}, node_locale: {eq: $nodeLocale}) {
      id
      ageGroup
      analysis {
        analysis
      }
      breeds
      brand {
        brandName
      }
      feedingRecommendation {
        feedingRecommendation
      }
      for_cats
      ingredients {
        ingredients
      }
      longDescription {
        longDescription
      }
      name
      nutritionAdditives {
        nutritionAdditives
      }
      segment
      sku {
        id
        barcode
        cartonAmount
        productCode
        weightInGrams
      }
      teaser
      type
      packagePhoto {
        id
        title
        description
        localFile {
          childImageSharp {
            fluid(maxWidth:900) {
              ...GatsbyImageSharpFluid
          }
          fixed(width:100,height:100) {
            ...GatsbyImageSharpFixed
          }
          }
        }
        
        
      }
      productPhoto {
        id
        title
        description
        localFile {
          childImageSharp {
            fluid(maxWidth:900) {
              ...GatsbyImageSharpFluid
          }
          fixed(width:100,height:100) {
            ...GatsbyImageSharpFixed
          }
          }
        }
      }
      kibblePhoto {
        id
        title
        description
        localFile {
          childImageSharp {
            fluid(maxWidth:900) {
              ...GatsbyImageSharpFluid
          }
          fixed(width:100,height:100) {
            ...GatsbyImageSharpFixed
          }
          }
        }
      }
      ambPhotos {
        id
        title
        description
        localFile {
          childImageSharp {
            fluid(maxWidth:900) {
              ...GatsbyImageSharpFluid
          }
          fixed(width:100,height:100) {
            ...GatsbyImageSharpFixed
          }
          }
        }
      }
    }
    site{
      siteMetadata{
        url
      }
    }
  }
`
